<!-- src/components/Signup.vue -->

<template src="@/../../../templates/brand/components/templates/user/Signup.html"> </template>

<script>
import _ from 'lodash/fp'
import { v4 as uuid } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
import validator from 'validator'
import purify from 'dompurify'
import LocationAddress from '@/components/LocationAddress.vue'
const _blacklist = '\\[?\$\_\{\}\[\\]\%\=\<\>\~\`\`\\\/"' // eslint-disable-line no-useless-escape
// import { PUBLIC_URL } from '@/util/common-constants'
// import _c from '@/../../../templates/site/UI/common/common-constants'

export default {
  name: 'Signup',
  components: { LocationAddress },
  created () {
    // if store config for onboarding requires user to select location
    // this.$http
    //   .get(`${PUBLIC_URL}/locations`)
    //   .then(res => (this.locations = res.data))
    //   .catch(err => {
    //     console.error(' :: error fetching public locations', err)
    //   })
    if (this.appconfig.siteConfig.customerTypeFields) {
      this.credentials.extradata = this.appconfig.siteConfig.customerTypeFields.reduce((acc, i) => {
        acc[i.name] = undefined
        return acc
      }, {})
      this.fields.extradata = this.appconfig.siteConfig.customerTypeFields.reduce((acc, i) => {
        acc[i.name] = {blurred: false, valid: true, type: i.type, required: i.required}
        return acc
      }, {})
    }
  },
  data () {
    return {
      locations: [],
      submitted: false,
      validateChildren: false,
      canSubmitInvalid: true,
      n1: Math.round(Math.random() * 10 + 1),
      n2: Math.round(Math.random() * 10 + 1),
      schmanser: 0,
      terms: false,
      url: '', // NB: this is a trap
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: '',
        userType: '',
        profile: {
          first: '',
          last: '',
          phone: '',
          // extradata: { dealer: '', dealerCode: 0 },
          billing: {
            aid: uuid(),
            country: 'CA',
            street: '',
            city: '',
            prov: '',
            apt: '',
            code: ''
          }
        }
      },
      fields: {
        terms: {blurred: false, valid: true, required: true},
        email: {blurred: false, valid: true, required: true, error: ''},
        captcha: {blurred: false, valid: true, required: true},
        userType: {blurred: false, valid: true, required: true},
        profile: {
          first: {blurred: false, valid: true, required: true},
          last: {blurred: false, valid: true, required: true},
          phone: {blurred: false, valid: true, required: true},
          billing: {
            country: {blurred: false, valid: true, required: true},
            street: {blurred: false, valid: true, required: true},
            city: {blurred: false, valid: true, required: true},
            prov: {blurred: false, valid: true, required: true},
            code: {blurred: false, valid: true, required: true},
          }
        }
      },
      err: false,
      msg: '',
      success: false,
      serverError: false,
      emailvalid: true,
      unamevalid: true,
    }
  },
  watch: {
    'credentials.profile.billing.country': function () {
      this.credentials.profile.billing.prov = ''
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    }),
    invalid: function () {
      return !(
        this.terms &&
        this.credentials.email &&
        this.emailvalid &&
        this.isEmail(this.credentials.email) &&
        this.credentials.profile.first &&
        this.credentials.profile.last &&
        // this.credentials.profile.billing.street &&
        // this.credentials.profile.billing.city &&
        // this.credentials.profile.billing.prov &&
        // this.credentials.profile.billing.code &&
        this.credentials.profile.phone &&
        (this.isAssociate(this.credentials.userType) ? this.credentials.extradata.dealer : true) &&
        Number(this.n1) + Number(this.n2) === (Number)(this.schmanser) &&
        this.appconfig.siteConfig.selfRegister
      )
    },
    invalidFields () {
      let _fields = []
      if (!this.fields.profile.first.valid) _fields.push('forms.firstName')
      if (!this.fields.profile.last.valid) _fields.push('forms.lastName')
      if (!this.fields.profile.billing.street.valid) _fields.push('forms.address')
      if (!this.fields.profile.billing.city.valid) _fields.push('forms.city')
      if (!this.fields.profile.billing.prov.valid) _fields.push('forms.Province')
      if (!this.fields.profile.billing.code.valid) _fields.push('forms.postalCode2')
      if (!this.fields.email.valid && this.fields.email.error === 'malformed') _fields.push('forms.valid')
      else if (!this.fields.email.valid) _fields.push('forms.email')
      if (!this.fields.profile.phone.valid) _fields.push('forms.phone')
      if (!this.fields.userType.valid) _fields.push('forms.custType')
      if (Number(this.n1) + Number(this.n2) !== (Number)(this.schmanser)) _fields.push('forms.mathQuestion')
      if (!this.fields.terms.valid) _fields.push('forms.terms')
      return _fields
    }
  },
  methods: {
    ...mapActions({ dologin: 'completeLogin'}), // not sure if this is correct, copied from Login.vue
    isAlpha (val) {
      return validator.isAlpha(val)
    },
    isAlphaNum (val) {
      return validator.isAlphanumeric(val)
    },
    isEmail (val) {
      return validator.isEmail(val)
    },
    isEmpty (val) {
      return validator.isEmpty(val)
    },
    isDealer (val) {
      return !!~['ho', 'as'].indexOf(val)
    },
    isAssociate (val) {
      return !!~['ds', 'dm'].indexOf(val)
    },
    updateField (evt) {
      const _field = evt.field.replace('address.', '')
      // console.info(' ::: signup update field event', evt)
      this.credentials.profile.billing[_field] = evt.value
      if (this.credentials.profile.billing[_field]) {
        this.fields.profile.billing[_field].blurred = true
        this.fields.profile.billing[_field].valid = true
      } else {
        this.fields.profile.billing[_field].blurred = true
        this.fields.profile.billing[_field].valid = false
      }
    },
    mask (evt) {
      const _path = evt.target.dataset.index // console.info('masking',evt,' checking ',_path, this.credentials)
      let _newval = purify.sanitize(evt.target.value)
      if (_newval) {
        _newval = validator.blacklist(_newval, _blacklist)
      } else {
        _newval = ''
      }
      evt.target.value = _newval
      _.set(_path, _newval, this.credentials)
      _.set(_path + '.blurred', true, this.fields)


      // const _parts = _path.split('.') // console.info('PARTS: ',_parts)
      // let _prop = this.credentials
      // for (let i = 0, c = _parts.length; i < c; i++) {
      //   if (i === c - 1) _prop[_parts[i]] = _newval
      //   else _prop = _prop[_parts[i]]
      // }
    },
    sanitize () {
      const _out = _.assign({}, this.credentials)
      _out.email = validator.normalizeEmail(_out.email.trim().toLowerCase())
      _out.profile.first = validator.blacklist(
        purify.sanitize(_out.profile.first, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.last = validator.blacklist(
        purify.sanitize(_out.profile.last, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.phone = validator.blacklist(
        purify.sanitize(_out.profile.phone, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.billing.city = validator.blacklist(
        purify.sanitize(_out.profile.billing.city, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.billing.code = validator.blacklist(
        purify.sanitize(_out.profile.billing.code, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.billing.street = validator.blacklist(
        purify.sanitize(_out.profile.billing.street, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.billing.apt = validator.blacklist(
        purify.sanitize(_out.profile.billing.apt, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.extradata.dealer = validator.blacklist(
        purify.sanitize(_out.profile.extradata.dealer, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.email = validator.normalizeEmail(_out.email)
      _out.profile.lang = this.$i18n.locale
      return _out
    },
    validateField (field) {
      const _field = _.get(field, this.fields)
      this.$set(_field, 'blurred', true)
      this.$set(_field, 'valid', !!_.get(field, this.credentials))
      this.validateChildren = false
    },
    validate () {
      let res = true
      if ((Number(this.n1) + Number(this.n2)) === (Number)(this.schmanser)) {
        this.$set(this.fields.captcha, 'valid', true)
      } else {
        this.$set(this.fields.captcha, 'valid', false)
        res = false
      }
      if (!this.credentials.email || !this.emailvalid) {
        this.$set(this.fields.email, 'valid', false)
        this.$set(this.fields.email, 'error', this.fields.email.error)
        res = false
      } else if (!this.isEmail(this.credentials.email)) {
        this.$set(this.fields.email, 'valid', false)
        this.$set(this.fields.email, 'error', 'malformed')
        res = false
      } else {
        this.$set(this.fields.email, 'valid', true)
      }
      if (this.terms) {
        this.$set(this.fields.terms, 'valid', true)
      } else {
        this.$set(this.fields.terms, 'valid', false)
        res = false
      }
      if (this.credentials.userType) {
        this.$set(this.fields.userType, 'valid', true)
      } else {
        this.$set(this.fields.userType, 'valid', false)
        res = false
      }
      if (this.credentials.profile.first) {
        this.$set(this.fields.profile.first, 'valid',  true)
      } else {
        this.$set(this.fields.profile.first, 'valid',  false)
        res = false
      }
      if (this.credentials.profile.last) {
        this.$set(this.fields.profile.last, 'valid', true)
      } else {
        this.$set(this.fields.profile.last, 'valid', false)
        res = false
      }
      if (this.credentials.profile.phone) {
        this.$set(this.fields.profile.phone, 'valid', true)
      } else {
        this.$set(this.fields.profile.phone, 'valid', false)
        res = false
      }
      // if (this.credentials.profile.billing.street) {
      //   this.$set(this.fields.profile.billing.street, 'valid', true)
      // } else {
      //   this.$set(this.fields.profile.billing.street, 'valid', false)
      //   res = false
      // }
      // if (this.credentials.profile.billing.city) {
      //   this.$set(this.fields.profile.billing.city, 'valid', true)
      // } else {
      //   this.$set(this.fields.profile.billing.city, 'valid', false)
      //   res = false
      // }
      // if (this.credentials.profile.billing.prov) {
      //   this.$set(this.fields.profile.billing.prov, 'valid', true)
      // } else {
      //   this.$set(this.fields.profile.billing.prov, 'valid', false)
      //   res = false
      // }
      // if (this.credentials.profile.billing.code) {
      //   this.$set(this.fields.profile.billing.code, 'valid', true)
      // } else {
      //   this.$set(this.fields.profile.billing.code, 'valid', false)
      //   res = false
      // }
      if (this.credentials.extradata) {
        for (const key in this.fields.extradata) {
          if (this.fields.extradata[key].required) {
            this.$set(this.fields.extradata[key], 'valid', !!this.credentials.extradata[key])
            if (!this.credentials.extradata[key]) res = false
          }
        }
      }

      return Promise.resolve(res)
    },
    async submit () {
      this.validateChildren = true
      if (!this.canSubmitInvalid && (this.invalid || this.submitted)) return false
      this.credentials.profile.lang = this.$i18n.locale
      this.submitted = true
      const valid = await this.validate()
      console.info(' ::: SUBMIT OK?', valid)
      if (!valid) return
      const _credentials = _.assign({}, this.credentials)
      _credentials.profile.extradata = _credentials.extradata
      delete _credentials.extradata
      this.$http
        .post(`public/users/signup?approval=${!this.appconfig.siteConfig.noApproval}`, _credentials)
        // signup(this.sanitize(this.credentials))
        .then(res => {
          if (res.data.success) {
            this.serverError = false
            this.success = true
            this.msg = 'success'
          } else if (res.data.msg) {
            this.serverError = true
            this.msg = res.data.msg
          } else {
            this.serverError = true
            this.msg = res.data.messages
          }
        })
        .catch(err => {
          this.serverError = true
          this.err = true
          this.msg = err
        })
    },
    testEmail: function () {
      // console.info(' ::::: email url', _c)
      if (!this.credentials.email) {
        return
      }
      if (!this.isEmail(this.credentials.email)) {
        this.$set(this.fields.email, 'error', 'malformed')
        this.$set(this.fields.email, 'valid', 'false')
        return
      }
      this.$http
        .get(
          `public/users/validemail/${this.credentials.email.trim().toLowerCase()}`
        )
        .then(res => {
          this.emailvalid = !res.data.success && res.data.error === 'no user'
          if (res.data.error === 'invalid domain') {
            this.emailvalid = false
            // this.err = true
            this.$set(this.fields.email, 'valid', false)
            this.$set(this.fields.email, 'error', 'invalid domain')
            this.msg = 'The domain entered for that email address is not valid'
          }
          // if (typeof res.data === 'string' || _.isEmpty(res.data)) {
          //   this.emailvalid = true
          // } else {
          //   this.emailvalid = false
          // }
        })
        .catch(err => {
          console.error('got an error', err)
        })
    },
    testUsername: function () {
      if (!this.credentials.username) {
        return
      }
      this.$http
        .get(`public/users/validusername/${this.credentials.username}`)
        .then(res => {
          if (_.isEmpty(res.data)) {
            this.unamevalid = true
          } else {
            this.unamevalid = false
          }
        })
        .catch(err => {
          console.error('got an error', err)
        })
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/user/Signup.css"></style>
